import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import WidgetsTable from "./WidgetsTable";
import EditText from "./views/EditText";
import RadioView from "./views/RadioView";
import DropDownView from "./views/DropDownView";
import SwitchView from "./views/SwitchView";
import SeekbarView from "./views/SeekbarView";
import AddIcon from '@material-ui/icons/Add';

export const WidgetTypes = {
    editText: 'EDIT_TEXT',
    seekbar: 'SEEKBAR',
    dropdown: 'DROPDOWN',
    radioButton: 'RADIOBUTTON',
    switch: 'SWITCH'
};

class Widgets extends React.Component {
    state = {
        name: 0,
        open: false,
        widgets: [],
        editIndex: -1,
    };
    onChange = event => {
        this.setState({name: event.target.value})
    };
    onAdd = event => {
        this.setState({type: event.target.value, open: true, editIndex: -1});
    };
    handleClose = () => {
        this.setState({open: false});
    };

    componentDidMount() {
        let widgets = this.props.input.value;
        if (widgets == null || widgets.constructor !== Array) {
            widgets = [];
        }
        this.setState({widgets: widgets})
    }

    onAddDone(widget) {
        let widgets = this.state.widgets;
        if (this.state.editIndex === -1) {
            widgets.push(widget);
        } else {
            widgets[this.state.editIndex] = widget;
        }
        this.setState({widgets: widgets, open: false, editIndex: -1});
        this.props.input.onChange(widgets);
    }

    onDelete(index) {
        let widgets = this.state.widgets;
        widgets.splice(index, 1);
        this.setState({widgets: widgets, open: false});
        this.props.input.onChange(widgets);
    }

    onEdit(index) {
        this.setState({name: this.getTypeForWidget(index), editIndex: index, open: true});
    }

    getTypeForWidget(atIndex) {
        let widget = this.state.widgets[atIndex];
        return widget.name
    }

    onCancelAdd() {
        this.setState({editIndex: -1, open: false});
    }

    render() {
        return (
            <div>
                {this.props.editable && <div style={{textAlign: 'center'}}>
                    <Select value={this.state.name}
                            style={{width: '20%'}}
                            onChange={this.onChange}
                    >
                        <MenuItem value={0}>Select Widget Type</MenuItem>
                        <MenuItem value={WidgetTypes.editText}>Edit Text</MenuItem>
                        <MenuItem value={WidgetTypes.radioButton}>Radio Button</MenuItem>
                        <MenuItem value={WidgetTypes.dropdown}>Dropdown List</MenuItem>
                        <MenuItem value={WidgetTypes.switch}>Toggle Button</MenuItem>
                        <MenuItem value={WidgetTypes.seekbar}>Seekbar</MenuItem>
                    </Select>
                    <Button style={{margin: '1em'}} variant="contained"
                            color="secondary" disabled={this.state.name === 0} onClick={this.onAdd}>
                        <AddIcon/>
                        Add
                    </Button>
                </div>
                }

                {this.state.name === WidgetTypes.editText &&
                <EditText open={this.state.open}
                          onCancel={this.onCancelAdd.bind(this)}
                          onAdd={this.onAddDone.bind(this)}
                          widget={this.state.editIndex === -1 ? undefined :
                              this.state.widgets[this.state.editIndex]}/>}

                {this.state.name === WidgetTypes.radioButton &&
                <RadioView
                    open={this.state.open}
                    onCancel={this.onCancelAdd.bind(this)}
                    onAdd={this.onAddDone.bind(this)}
                    widget={this.state.editIndex === -1 ? undefined :
                        this.state.widgets[this.state.editIndex]}/>}
                {this.state.name === WidgetTypes.dropdown &&
                <DropDownView
                    open={this.state.open}
                    onCancel={this.onCancelAdd.bind(this)}
                    onAdd={this.onAddDone.bind(this)}
                    widget={this.state.editIndex === -1 ? undefined :
                        this.state.widgets[this.state.editIndex]}/>}
                {this.state.name === WidgetTypes.switch &&
                <SwitchView
                    open={this.state.open}
                    onAdd={this.onAddDone.bind(this)}
                    onCancel={this.onCancelAdd.bind(this)}
                    widget={this.state.editIndex === -1 ? undefined :
                        this.state.widgets[this.state.editIndex]}/>}
                {this.state.name === WidgetTypes.seekbar &&
                <SeekbarView
                    open={this.state.open}
                    onCancel={this.onCancelAdd.bind(this)}
                    onAdd={this.onAddDone.bind(this)}
                    widget={this.state.editIndex === -1 ? undefined :
                        this.state.widgets[this.state.editIndex]}/>}

                <WidgetsTable
                    onEdit={this.onEdit.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    widgets={this.state.widgets}/>
            </div>
        );
    }
}

export default Widgets;