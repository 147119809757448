import React from "react"
import {compose, withProps} from "recompose"

const {GoogleMap, Marker, withGoogleMap, withScriptjs} = require("react-google-maps");

const {SearchBox} = require("react-google-maps/lib/components/places/SearchBox");
const google = window.google;

const url = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyB-jgVPiHhktSfS7UXIbQrR954g2q0J3zg&libraries=geometry,places`;
const MyMapComponent = compose(
    withProps({
        googleMapURL: url,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    let position = props.position;
    if (position == null || position.lat === undefined) {
        position = {lat: 21.381, lng: 39.8579};
    }
    return <GoogleMap
        defaultZoom={13}
        center={position}
        onClick={props.onClick}
    >
        {props.editable && <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Search"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    marginTop: `27px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>}
        {props.isMarkerShown && <Marker position={props.position} onClick={props.onMarkerClick}/>}
    </GoogleMap>
});


class MyFancyComponent extends React.PureComponent {
    state = {
        isMarkerShown: false,
        position: null
    };

    onSearchBoxMounted = ref => {
        this.setState({searchBox: ref})
    };

    onPlacesChanged = () => {
        const places = this.state.searchBox.getPlaces();

        places.forEach(place => {
            let location = [place.geometry.location.lng(), place.geometry.location.lat()];
            this.setState({position: place.geometry.location});
            this.props.input.onChange(location);
            this.delayedShowMarker();
        });

    };

    componentDidMount() {
        console.log(this.props);
        if (this.props.editable) {
            if (this.props.input != null) {
                let pos = {lat: this.props.input.value[1], lng: this.props.input.value[0]};
                this.setState({position: pos})
            }
        } else if (this.props.record != null) {
            let location = this.props.record[this.props.source];
            if (location != null) {
                let pos = {lat: location[1], lng: location[0]};
                this.setState({position: pos})
            }
        }
        this.delayedShowMarker()
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({isMarkerShown: true})
        }, 3000)
    };

    handleMarkerClick = () => {
        this.setState({isMarkerShown: false});
        this.delayedShowMarker()
    };
    handleMapClick = (event) => {
        if (this.props.editable) {
            let location = [event.latLng.lng(), event.latLng.lat()];
            this.props.input.onChange(location);
            this.setState({position: event.latLng});
        }
    };

    render() {
        return (
            <MyMapComponent
                isMarkerShown={true}
                name={"location"}
                onMarkerClick={this.handleMarkerClick}
                onClick={this.handleMapClick}
                editable={this.props.editable}
                onSearchBoxMounted={this.onSearchBoxMounted.bind(this)}
                onPlacesChanged={this.onPlacesChanged.bind(this)}
                position={this.state.position}
            />
        )
    }
}

export default MyFancyComponent;