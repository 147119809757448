import React from 'react';
import Icon from '@material-ui/icons/AccountCircleOutlined';
import {
    BooleanField,
    Datagrid,
    DeleteWithConfirmButton,
    EmailField,
    List,
    NumberField,
    ReferenceManyField,
    Show,
    ShowButton,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import FullnameField from "../common/FullnameField";
import {ConsultationGrid} from "../consultations/Consultation";
import {TimeField} from "../common/TimeField";
import {MoneyField} from "../common/MoneyField";
import {PurchaseGrid} from "../purchase/Receipt";

export const UserIcon = Icon;

export const CustomerList = props => (
    <List {...props} bulkActionButtons={false}>
        <UserDataGrid/>
    </List>
);
export const UserDataGrid = (props) => (
    <Datagrid {...props} rowClick="show">
        <FullnameField/>
        <TextField source="username" label={"Phone"}/>
        <EmailField source="email"/>
        <NumberField source="balance"/>
        <BooleanField source="enabled"/>
        <ShowButton/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
export const ShowUser = (props) => (
    <Show {...props} title={<FullnameField/>}>
        <TabbedShowLayout>
            <Tab label={"General"}>
                <TextField source="name"/>
                <TextField source="username" label={"Phone"}/>
                <EmailField source="email"/>
                <MoneyField source="balance" label={"Balance"} addLabel={true}/>
                <BooleanField source="enabled"/>
                <TimeField source={"loginTime"}
                           addLabel={"true"}
                           label={"Login Time"}/>
            </Tab>
            <Tab label={"Consultations"}>
                <ReferenceManyField target={"customerId"} reference={"consultations"} addLabel={false}>
                    <ConsultationGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label={"Packages Purchases"}>
                <ReferenceManyField target={"userId"}
                                    filter={{"sale": false}}
                                    reference={"receipts1"} addLabel={false}>
                    <PurchaseGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);