import React from 'react';
import {Admin, Resource} from 'react-admin';
import {SERVER_IP} from "./index";
import OurClient from "./rest/OurClient";
import {CreateDoctor, DoctorIcon, DoctorList, EditDoctor, ShowDoctor} from "./user/doctor/Doctor";
import {CreateDepartment, DepartmentIcon, DepartmentList, EditDepartment} from "./department/Department";
import {CustomerList, ShowUser, UserIcon} from "./user/User";
import Dashboard from "./dashboard/Dashboard";
import Layout from "./layout/Layout";
import {CityIcon, CreateCity, EditCity, ListCities, ShowCity} from "./city/City";
import {CreatePurchaseModel, EditPurchaseModel, ListPurchaseModels} from "./purchase/Purchase";
import {ListPurchaseReceipts, ListSaleReceipts} from "./purchase/Receipt";
import {ConsultationList} from "./consultations/Consultation";
import AuthClient from "./login/AuthClient";
import Login from "./login/Login";
import {CategoryList, CreateCategory, EditCategory} from "./department/Category";


function App() {
    return (
        <Admin dataProvider={OurClient(SERVER_IP)}
               authProvider={AuthClient}
               loginPage={Login}
               dashboard={Dashboard}
               appLayout={Layout}>
            <Resource context={'route'}
                      name={'department'}
                      list={DepartmentList}
                      icon={DepartmentIcon}
                      create={CreateDepartment}
                      edit={EditDepartment}
                      options={{label: "Departments"}}/>
            <Resource context={'route'}
                      name={'cities'}
                      list={ListCities}
                      icon={CityIcon}
                      show={ShowCity}
                      create={CreateCity}
                      edit={EditCity}
                      options={{label: "Cities"}}/>
            <Resource context={'route'}
                      name={'customer'}
                      list={CustomerList}
                      show={ShowUser}
                      options={{label: "Users"}}
                      icon={UserIcon}/>
            <Resource context={'route'}
                      name={'consultant'}
                      icon={DoctorIcon}
                      create={CreateDoctor}
                      edit={EditDoctor}
                      show={ShowDoctor}
                      options={{label: "Consultants"}}
                      list={DoctorList}/>

            <Resource name={"packages"}
                      list={ListPurchaseModels}
                      create={CreatePurchaseModel}
                      edit={EditPurchaseModel}/>

            <Resource name={"receipts1"}
                      list={ListPurchaseReceipts}/>

            <Resource name={"receipts2"}
                      list={ListSaleReceipts}/>
            <Resource name={"consultations"}
                      list={ConsultationList}
            />
            <Resource name={"categories"}
                      list={CategoryList}
                      edit={EditCategory}
                      create={CreateCategory}/>

        </Admin>
    );
}

export default App;
