import React from 'react';

import Icon from 'mdi-material-ui/Stethoscope'
import FullnameField from "../../common/FullnameField";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {Card, CardContent, CardHeader, CardMedia} from "@material-ui/core";

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    EmailField,
    FormTab,
    FunctionField,
    List,
    maxLength,
    maxValue,
    minLength,
    minValue,
    number,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {ConsultationGrid} from "../../consultations/Consultation";

export const DoctorIcon = Icon;
const genders = [
    {id: "MALE", name: "Male"},
    {id: "FEMALE", name: "Female"},
    {id: "UNSPECIFIED", name: "No need"}
];

export const DoctorList = props => (
    <List {...props} bulkActionButtons={false}>
        <DoctorDataGrid/>
    </List>
);
export const DoctorDataGrid = (props) => (
    <Datagrid rowClick="show" {...props}>
        <FullnameField/>
        <TextField source="username"/>
        <EmailField source="email"/>
        <NumberField source="fees"/>
        <TextField source="gender"/>
        <ReferenceField source="cityId" reference="cities">
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="departmentId" reference="department">
            <TextField source="name"/>
        </ReferenceField>

        <BooleanField source="adminVerified"/>
        <BooleanField source="online"/>
        <EditButton/>
        <DeleteWithConfirmButton/>

    </Datagrid>
);

export const CreateDoctor = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const EditDoctor = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);
const validateFee = [required(), number(), minValue(10), maxValue(999)];
const validateUsername = [required(), minLength(12), maxLength(12)];
const validatePassword = [required(), minLength(6)];
const createDefaultValue = {_type: "DOCTOR"};
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'} defaultValue={createDefaultValue}>
        <FormTab label={"General"}>
            <TextInput source={"name"} validate={required()}/>
            <TextInput source={"username"}
                       validate={validateUsername}
                       label={"Phone number"}
                       options={{helperText: "e.g 966581234567"}}/>
            <TextInput source={"email"} validate={required()}/>
            <TextInput source={"password"}
                       options={{helperText: "Please remember it"}}
                       validate={validatePassword}/>
            <SelectInput source={"gender"} choices={genders} validate={required()}/>
            <NumberInput source={"fees"} validate={validateFee}/>
            <BooleanInput source={"adminVerified"} validate={required()}/>
            <TextInput source={"about"} options={{fullWidth: true}}/>
        </FormTab>
        <FormTab label={"Other"}>
            <ReferenceInput source={"cityId"} reference={"cities"}>
                <SelectInput optionText={"name"}/>
            </ReferenceInput>
            <ReferenceInput source={"departmentId"} reference={"department"}>
                <SelectInput optionText={"name"}/>
            </ReferenceInput>

        </FormTab>
    </TabbedForm>
);
export const ShowDoctor = (props) => (
    <Show {...props} title={<FullnameField/>}>
        <TabbedShowLayout>
            <Tab label={"General"}>
                <TextField source="username"/>
                <EmailField source="email"/>
                <NumberField source="fees"/>
                <TextField source="gender"/>
                <ReferenceField source="cityId" reference="cities">
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="departmentId" reference="department">
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source="adminVerified"/>
                <BooleanField source="online"/>
            </Tab>
            <Tab label={"Documents"}>
                <ReferenceField source="levelId" reference="doctorLevel">
                    <TextField source="name"/>
                </ReferenceField>
                <FunctionField source={"documents"}
                               addLabel={false}
                               fullWidth
                               render={record => ListDocuments(record.documents)}/>

            </Tab>
            <Tab label={"Consultations"}>
                <ReferenceManyField target={"doctorId"} reference={"consultations"} addLabel={false}>
                    <ConsultationGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
const styles = {
    card: {
        width: 300,
        height: 350,
        margin: '1em',
    },
    media: {
        height: 250,
        width: 300
    },
};

function ListDocuments(documents) {
    if (documents == null || documents.length === 0) {
        return <h4>No Documents</h4>
    }
    return <GridList cellHeight={400} cols={3}>
        {documents.map(doc =>
            <GridListTile key={doc.name} cols={1}>
                <Card style={styles.card}>
                    <CardHeader title={doc.name}/>

                    <a href={doc.url} target={"blank"}>
                        <CardMedia style={styles.media}
                                   image={doc.url}
                        />
                    </a>
                    <CardContent style={{textAlign: "center"}}>
                        <span>{doc.note}
                        </span>
                    </CardContent>
                </Card>
            </GridListTile>)}
    </GridList>
}