import React from 'react';
import {Datagrid, FunctionField, List, ReferenceField, TextField} from 'react-admin';
import Icon from '@material-ui/icons/Receipt';
import {MoneyField} from "../common/MoneyField";
import {TimeField} from "../common/TimeField";
import Chip from "@material-ui/core/Chip";
import AndroidIcon from '@material-ui/icons/Android';
import IosIcon from '@material-ui/icons/PhoneIphone';
import {Avatar} from "@material-ui/core";

export const ReceiptIcon = Icon;
export const ListPurchaseReceipts = (props) => (
    <List {...props} filter={{"sale": false}} bulkActionButtons={false}>
        <PurchaseGrid/>
    </List>
);

export const PurchaseGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"purchaseId"}/>
        <ReferenceField source={"packageId"}
                        label={"Purchasable"}
                        linkType={'edit'}
                        reference={"packages"}>
            <TextField source={"title"}/>
        </ReferenceField>
        <ReferenceField source={"userId"}
                        linkType={'show'}
                        reference={"customer"}>
            <TextField source={"name"}/>
        </ReferenceField>
        <MoneyField source={"amount"} label={"Amount"}/>
        <FunctionField label={"Platform"}
                       render={record => <Chip
                           avatar={<Avatar style={{background: "#00000000"}}>{record.ios ? <IosIcon/> :
                               <AndroidIcon/>}</Avatar>}
                           label={record.ios ? "iOS" : "Android"}/>}/>
        <TimeField source={"time"}/>
    </Datagrid>
);

export const SaleGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"purchaseId"}/>
        <ReferenceField source={"consultationId"}
                        label={"Consultation"}
                        linkType={'edit'}
                        reference={"consultations"}>
            <TextField source={"title"}/>
        </ReferenceField>
        <ReferenceField source={"userId"}
                        linkType={'show'}
                        reference={"customer"}>
            <TextField source={"name"}/>
        </ReferenceField>
        <MoneyField source={"amount"} label={"Amount"}/>
        <FunctionField label={"Platform"}
                       render={record => <span>{record.ios ? "iOS" : "Android"}</span>}/>
        <TimeField source={"time"}/>

    </Datagrid>
);

export const ListSaleReceipts = (props) => (
    <List {...props} filter={{"sale": true}} bulkActionButtons={false}>
        <SaleGrid/>
    </List>
);