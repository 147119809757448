import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import React from "react";
import Menu from './Menu';

export const lightTheme = {
    palette: {
        secondary: {
            light: '#60ad5e',
            main: '#2e7d32',
            dark: '#005005',
            contrastText: '#fff',
        },
        primary: {
            light: '#60ad5e',
            main: '#2e7d32',
            dark: '#005005',
            contrastText: '#fff',
        },
    },
};
const CustomLayout = props => (
    <Layout {...props} menu={Menu}/>
);
export default connect(
    state => ({
        theme: lightTheme
    }),
    {}
)(CustomLayout);