import React from 'react';
import Icon from '@material-ui/icons/Accessibility'
import {
    ChipField,
    Datagrid,
    DeleteWithConfirmButton,
    List,
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    TextField
} from 'react-admin';
import {TimeField} from "../common/TimeField";
import {MoneyField} from "../common/MoneyField";

export const ConsultationIcon = Icon;

export const ConsultationList = props => (
    <List {...props}>
        <ConsultationGrid/>
    </List>
);

export const ConsultationGrid = (props) => (
    <Datagrid {...props}>
        <TextField source="title"/>
        <ReferenceField source="departmentId" reference="department" linkType={'show'}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="doctorId" reference="doctor" linkType={'show'}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="customerId" reference="customer" linkType={'show'}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceManyField label="Receipt" reference="receipts2" target="consultationId">
            <SingleFieldList>
                <MoneyField source="amount"/>
            </SingleFieldList>
        </ReferenceManyField>
        <ChipField source="status"/>
        <TimeField label={"Time"} source={"time"}/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
