import React from 'react';
import AvatarField from './AvatarField';
import pure from 'recompose/pure';

const FullNameField = ({record = {}, size}) => (
    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
        <AvatarField record={record} size={size}/>
        &nbsp;<b>{record.name}</b>
    </div>
);

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'name',
    label: 'Name',
};

export default PureFullNameField;