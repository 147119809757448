import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ChipInput from 'material-ui-chip-input'
import {Dialog, DialogActions, FormControlLabel, Switch} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {WidgetTypes} from "../Widgets";


class DropDownView extends React.Component {
    state = {
        widget: {},
        errorTitle: false,
        errorChips: false,
    };
    componentDidMount() {
        this.updateState(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateState(nextProps)
    }

    updateState(props) {
        let widget = props.widget;
        if (widget === undefined) {
            widget = {};
        }
        this.setState({widget: widget, errorTitle: false, errorChips: false});
    }

    handleChange = name => event => {
        let widget = this.state.widget;
        if (name === 'name') {
            widget.title = event.target.value;
        } else if (name === 'optional') {
            widget.optional = event.target.checked
        } else {
            console.log("Unhandled attribute of dropdown widget");
        }
        this.setState({widget: widget, errorTitle: false});
    };

    verifyAndAdd = () => {
        let widget = this.state.widget;
        if (widget.title == null || widget.title.length < 2) {
            this.setState({errorTitle: true})
        } else if (widget.values == null || widget.values.length === 0) {
            this.setState({errorChips: true})
        } else {
            widget.name = WidgetTypes.dropdown;
            this.props.onAdd(widget)
        }

    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false, errorChips: false});
    };
    onDelete = (chip, index) => {
        let widget = this.state.widget;
        widget.values.splice(index, 1);
        this.setState({widget: widget, firstAdd: false});
    };
    onAdd = chip => {
        let widget = this.state.widget;
        if (widget.values != null) {
            widget.values.push(chip);
        } else {
            widget.values = [];
            widget.values.push(chip)
        }
        this.setState({widget: widget, firstAdd: false, errorChips: false});
    };

    render() {

        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Dropdown List</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Write the title and list values
                    </DialogContentText>
                    <TextField
                        style={{marginBottom: '1em', marginTop: '1em'}}
                        error={this.state.errorTitle}
                        id={"name"}
                        label="Title"
                        value={this.state.widget.title}
                        fullWidth
                        onChange={this.handleChange('name').bind(this)}
                        required/>
                    <ChipInput
                        error={this.state.errorChips}
                        style={{marginBottom: '1em'}}
                        value={this.state.widget.values == null ? [] : this.state.widget.values}
                        fullWidth
                        required
                        onAdd={this.onAdd}
                        onDelete={this.onDelete}
                        placeholder="List values"
                    />
                    <FormControlLabel
                        style={{marginBottom: '1em'}}
                        control={
                            <Switch
                                onChange={this.handleChange('optional')}
                                name={"optional"}
                                checked={this.state.widget.optional}/>
                        }
                        label={"Optional"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button color={'primary'} onClick={this.verifyAndAdd}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default DropDownView;