import React from 'react';
import ColorPicker from "material-ui-color-picker";

export class ColorInput extends React.Component {
    state = {
        color: "#000"
    };

    componentDidMount() {
        this.setState({color: this.props.input.value})
    }

    render() {
        return (
            <div style={{marginBottom: '2em'}}>
                <ColorPicker
                    label={this.props.label}
                    defaultValue='#000000'
                    value={this.state.color}
                    onChange={color => {
                        this.props.input.onChange(color);
                        console.log(color);
                        this.setState({color: color})
                    }}

                />
            </div>
        );
    }
}