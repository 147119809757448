import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Dialog, DialogActions, Switch} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {WidgetTypes} from "../Widgets";


class SwitchView extends React.Component {
    state = {
        widget: {},
        errorTitle: false,
    };

    componentDidMount() {
        this.updateState(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateState(nextProps)
    }

    updateState(props) {
        let widget = props.widget;
        if (widget === undefined) {
            widget = {};
        }
        this.setState({widget: widget, errorTitle: false});
    }

    handleChange = name => event => {
        let widget = this.state.widget;
        switch (name) {
            case 'name':
                widget.title = event.target.value;
                break;
            case 'checked':
                widget.checked = event.target.checked;
                break;
            case 'optional':
                widget.optional = event.target.checked;
                break;
            default:
                console.log("Unexpected case for switch view");
        }
        this.setState({widget: widget, errorTitle: false});
    };

    verifyAndAdd = () => {
        let widget = this.state.widget;
        if (widget.title == null || widget.title.length < 2) {
            this.setState({errorTitle: true})
        } else {
            widget.name = WidgetTypes.switch;
            this.props.onAdd(widget)
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({errorTitle: false});
    };

    render() {
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Switch</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Write title and default value which can be on or off
                    </DialogContentText>
                    <TextField
                        style={{marginTop: '1em', marginBottom: '1em'}}
                        error={this.state.errorTitle}
                        id={"name"}
                        label={'Title'}
                        value={this.state.widget.title}
                        fullWidth
                        onChange={this.handleChange('name')}
                        required/>
                    <FormControlLabel
                        style={{marginBottom: '1em'}}
                        control={<Switch
                            id={"checked"}
                            checked={this.state.widget.checked}
                            fullWidth
                            onChange={this.handleChange('checked')}
                        />}
                        label={"Default On/Off"}
                    />
                    <FormControlLabel
                        style={{marginBottom: '1em'}}
                        control={
                            <Switch
                                onChange={this.handleChange('optional')}
                                name={"optional"}
                                checked={this.state.widget.optional}/>
                        }
                        label={"Optional"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button color={'primary'} onClick={this.verifyAndAdd}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SwitchView;