import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';

class WidgetsTable extends React.Component {
    state = {
        widgets: []
    };

    componentDidMount() {
        this.setState({widgets: this.props.widgets})
    }

    editWidget = index => {
        this.props.onEdit(index);
    };

    deleteWidget = index => {
        this.props.onDelete(index);
    };

    componentWillReceiveProps(newProps, context) {
        this.setState({widgets: newProps.widgets});
    }

    render() {
        return (
            <div>
                {(this.state.widgets == null || this.state.widgets.length === 0) &&
                <h1 style={{textAlign: 'center'}}>No Widgets</h1>}
                {(this.state.widgets != null && this.state.widgets.length > 0) &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{"#"}</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.widgets.map((widget, index) =>
                            <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell><b>{widget.title}</b></TableCell>
                                <TableCell>{widget.optional ? "Yes" : "No"}</TableCell>
                                <TableCell>
                                    <div>
                                        <Button style={{marginRight: '1em'}} color={'secondary'}
                                                onClick={() => this.editWidget(index)}>
                                            Edit
                                        </Button>
                                        <Button color={'primary'}
                                                onClick={() => this.deleteWidget(index)}>
                                            Delete
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                }
            </div>
        );
    }

    getDefaultValue(widget) {
        if (widget.defaultValue === undefined) {
            return "NA"
        }
        return widget.defaultValue
    }
}

export default WidgetsTable;