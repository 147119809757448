import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import placeholder from '../images/placeholder.png';

const AvatarField = ({record, size}) => (
    <Avatar
        src={record.image === null ? placeholder : record.image}
        size={size}
        style={{width: size, height: size}}
    />
);

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;