import React from 'react';
import Icon from '@material-ui/icons/ExtensionOutlined';
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    FormTab,
    List,
    required,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import {Field} from 'react-final-form';
import ImageUpload from "../common/ImageUpload";
import {ColorInput} from "../common/ColorInput";

export const DepartmentIcon = Icon;

export const DepartmentList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <TextField source="description"/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const CreateDepartment = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const EditDepartment = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={"General"}>
            <TextInput source={"name"} validate={required()}/>
            <Field name={'color'}
                   component={ColorInput}
                   source={'color'}
                   validate={required()}/>
            <TextInput source={"description"}
                       fullWidth
                       validate={required()}/>

        </FormTab>

        <FormTab label={"Thumbnail"}>
            <Field name={"thumb"}
                   source={"thumb"}
                   validate={required()}
                   component={ImageUpload}/>
        </FormTab>
    </TabbedForm>
);