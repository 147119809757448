import React from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Icon from '@material-ui/icons/MonetizationOn'

export const MoneyField = ({record, source}) => (
    <Chip
        label={record[source]}
        avatar={<Avatar style={{background: "#00000000"}}>
            <Icon/>
        </Avatar>}
    />
);