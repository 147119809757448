import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';

import {DashboardMenuItem, MenuItemLink, Responsive, translate,} from 'react-admin';


import SubMenu from './SubMenu';
import {DoctorIcon} from "../user/doctor/Doctor";
import {UserIcon} from "../user/User";
import {DepartmentIcon} from "../department/Department";
import {CityIcon} from "../city/City";
import {PurchaseModelIcon} from "../purchase/Purchase";
import {ReceiptIcon} from "../purchase/Receipt";
import PurchaseIcon from "@material-ui/icons/AttachMoney"
import {ConsultationIcon} from "../consultations/Consultation";
import {CategoryIcon} from "../department/Category";

class Menu extends Component {
    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };
    state = {
        menuDoctors: false,
        menuPurchase: false,
        menuDepartment: false,
    };

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        const {onMenuClick, open, logout} = this.props;
        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick}/>

                <MenuItemLink
                    to={`/consultations`}
                    primaryText={"Consultations"}
                    leftIcon={<ConsultationIcon/>}
                    onClick={onMenuClick}/>

                <SubMenu
                    handleToggle={() => this.handleToggle('menuDoctors')}
                    isOpen={this.state.menuDoctors}
                    sidebarIsOpen={open}
                    name="Doctors"
                    icon={<DoctorIcon/>}>
                    <MenuItemLink
                        to={`/consultant`}
                        primaryText={"Consultants"}
                        leftIcon={<DoctorIcon/>}
                        onClick={onMenuClick}/>

                </SubMenu>

                <SubMenu
                    handleToggle={() => this.handleToggle('menuPurchase')}
                    isOpen={this.state.menuPurchase}
                    sidebarIsOpen={open}
                    name="Purchases"
                    icon={<PurchaseIcon/>}>
                    <MenuItemLink
                        to={`/packages`}
                        primaryText={"Purchasable"}
                        leftIcon={<PurchaseModelIcon/>}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/receipts1`}
                        primaryText={"Purchase Receipts"}
                        leftIcon={<ReceiptIcon/>}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/receipts2`}
                        primaryText={"Sale Receipts"}
                        leftIcon={<ReceiptIcon/>}
                        onClick={onMenuClick}
                    />
                </SubMenu>

                <MenuItemLink
                    to={`/customer`}
                    primaryText={"Customers"}
                    leftIcon={<UserIcon/>}
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/cities`}
                    primaryText={"Cities"}
                    leftIcon={<CityIcon/>}
                    onClick={onMenuClick}
                />
                <SubMenu
                    handleToggle={() => this.handleToggle('menuDepartment')}
                    isOpen={this.state.menuDepartment}
                    sidebarIsOpen={open}
                    name="Departments"
                    icon={<DepartmentIcon/>}>
                    <MenuItemLink
                        to={`/department`}
                        primaryText={"Departments"}
                        leftIcon={<DepartmentIcon/>}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/categories`}
                        primaryText={"Categories"}
                        leftIcon={<CategoryIcon/>}
                        onClick={onMenuClick}
                    />
                </SubMenu>

                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);