import React from 'react';
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    FunctionField,
    List,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import Icon from '@material-ui/icons/MonetizationOn';
import {Field} from 'react-final-form';
import {ColorInput} from "../common/ColorInput";

export const PurchaseModelIcon = Icon;

export const ListPurchaseModels = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source={"title"}/>
            <TextField source={"sku"}/>
            <TextField source={"credits"}/>
            <FunctionField
                label={"Price"}
                render={record => <span>{`${record.price}${record.currency}`}</span>}/>
            <TextField source={"purchaseCount"}/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const CreatePurchaseModel = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const EditPurchaseModel = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = (props) => (
    <SimpleForm {...props} redirect={'list'}>
        <TextInput source={"title"} validate={required()}/>
        <TextInput source={"sku"}
                   validate={required()}
                   options={{helperText: "Purchase id from Google Play or App Store"}}/>
        <NumberInput source={"price"}
                     options={{helperText: "Price in SAR"}}
                     validate={required()}/>
        <NumberInput source={"credits"} validate={required()}/>
        <Field
            name="backgroundColor"
            label={"Background Color"}
            component={ColorInput}
            source={"backgroundColor"}
            validate={required()}
        />
        <Field
            label={"Text Color"}
            name="textColor"
            component={ColorInput}
            source={"textColor"}
            validate={required()}
        />
    </SimpleForm>
);