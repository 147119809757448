import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ChipInput from 'material-ui-chip-input'
import {Dialog, DialogActions, FormControlLabel, Switch} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {WidgetTypes} from "../Widgets";


class RadioView extends React.Component {
    state = {
        widget: {},
        errorTitle: false,
        firstAdd: true,
        errorChips: false
    };

    componentDidMount() {
        this.updateState(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateState(nextProps)
    }

    updateState(props) {
        let widget = props.widget;
        if (widget === undefined) {
            widget = {};
        }
        this.setState({widget: widget, errorTitle: false, errorChips: false});
    }
    handleChange = name => event => {
        let widget = this.state.widget;
        if (name === 'name') {
            widget.title = event.target.value;
        } else if (name === 'optional') {
            widget.optional = event.target.checked
        } else {
            console.log("Unhandled default case");
        }
        this.setState({widget: widget, errorTitle: false});
    };


    verifyAndAdd = () => {
        let widget = this.state.widget;
        if (widget.title == null || widget.title.length < 2) {
            this.setState({errorTitle: true})
        } else if (widget.titles == null || widget.titles.length === 0) {
            this.setState({errorChips: true})
        } else {
            widget.name = WidgetTypes.radioButton;
            this.props.onAdd(widget)
        }

    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false});
    };
    onDelete = (chip, index) => {
        let widget = this.state.widget;
        widget.titles.splice(index, 1);
        this.setState({widget: widget, firstAdd: false});
    };
    onAdd = chip => {
        let widget = this.state.widget;
        if (widget.titles != null) {
            widget.titles.push(chip);
        } else {
            widget.titles = [];
            widget.titles.push(chip)
        }
        this.setState({widget: widget, firstAdd: false, errorChips: false});
    };

    render() {
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Radio Button</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Write the group name and button titles.
                    </DialogContentText>
                    <TextField
                        style={{marginTop: '1em', marginBottom: '1em'}}
                        error={this.state.errorTitle}
                        id={"name"}
                        label={'Title'}
                        value={this.state.widget.title}
                        fullWidth
                        onChange={this.handleChange('name').bind(this)}
                        required/>

                    <ChipInput
                        required
                        error={this.state.errorChips}
                        style={{marginBottom: '1em'}}
                        value={this.state.widget.titles == null ? [] : this.state.widget.titles}
                        fullWidth
                        onAdd={this.onAdd}
                        onDelete={this.onDelete}
                        placeholder={'Button Titles'}
                    />
                    <FormControlLabel
                        style={{marginBottom: '1em'}}
                        control={
                            <Switch
                                onChange={this.handleChange('optional')}
                                name={"optional"}
                                checked={this.state.widget.optional}/>
                        }
                        label={"Optional"}/>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button color={'primary'} onClick={this.verifyAndAdd}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default RadioView;