import React from 'react';
import Icon from '@material-ui/icons/LocationCity';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import MyMap from "../common/MyMap";
import {Field} from 'react-final-form';
import {UserDataGrid} from "../user/User";

export const CityIcon = Icon;
export const ListCities = (props) => (
    <List {...props} >
        <Datagrid>
            <TextField source={"name"}/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const CreateCity = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const EditCity = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);
export const ShowCity = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <FormTab label={"General"}>
                <TextField source={"name"}/>
                <MyMap source={"location"}/>
            </FormTab>
            <FormTab label={"Doctors"}>
                <ReferenceManyField target={"cityId"} reference={"doctor"} addLabel={false}>
                    <UserDataGrid/>
                </ReferenceManyField>
            </FormTab>
            <FormTab label={"Customers"}>
                <ReferenceManyField target={"cityId"} reference={"customer"} addLabel={false}>
                    <UserDataGrid/>
                </ReferenceManyField>
            </FormTab>

        </TabbedShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={"General"}>
            <TextInput source={"name"} validate={required()}/>
        </FormTab>
        <FormTab label={"Location"}>
            <Field name={"location"}
                   source={"location"}
                   editable
                   component={MyMap}
            />
        </FormTab>
    </TabbedForm>
);