import React from 'react';
import Icon from '@material-ui/icons/Category';
import {
    ArrayField,
    ChipField,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    FormTab,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import {Field} from "react-final-form";
import Widgets from "./Widgets";

export const CategoryIcon = Icon;

export const CategoryList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <ReferenceField source={"departmentId"} reference={"department"}>
                <ChipField source={"name"}/>
            </ReferenceField>
            <ArrayField source="widgets">
                <SingleFieldList>
                    <ChipField source="title"/>
                </SingleFieldList>
            </ArrayField>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const CreateCategory = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);
export const EditCategory = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={"General"}>
            <TextInput source={"name"} validate={required()}/>
            <ReferenceInput source={"departmentId"} reference={"department"} validate={required()}>
                <SelectInput optionText={"name"}/>
            </ReferenceInput>
            <TextInput source={"description"}
                       fullWidth
                       validate={required()}/>
        </FormTab>
        <FormTab label={"Consultancy Form"}>
            <Field name={'widgets'}
                   source={'widgets'}
                   editable={true}
                   component={Widgets}/>
        </FormTab>
    </TabbedForm>
);