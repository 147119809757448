import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Dialog, DialogActions, FormControlLabel, Switch} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {WidgetTypes} from "../Widgets";

class SeekbarView extends React.Component {
    state = {
        widget: {},
        errorTitle: false,
    };

    componentDidMount() {
        this.updateState(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateState(nextProps)
    }

    updateState(props) {
        let widget = props.widget;
        if (widget === undefined) {
            widget = {};
        }
        this.setState({widget: widget, errorTitle: false});
    }

    handleChange = name => event => {
        let widget = this.state.widget;
        switch (name) {
            case 'name':
                widget.title = event.target.value;
                break;
            case 'defaultValue':
                widget.defaultValue = event.target.value;
                break;
            case 'min':
                widget.min = event.target.value;
                break;
            case 'max':
                widget.max = event.target.value;
                break;
            case 'optional':
                widget.optional = event.target.checked;
                break;
            default:
                console.log("Unhandled seek bar case");
        }
        this.setState({widget: widget, errorTitle: false});
    };

    verifyAndAdd = () => {
        let widget = this.state.widget;
        if (widget.title == null || widget.title.length < 2) {
            this.setState({errorTitle: true})
        } else {
            widget.name = WidgetTypes.seekbar;
            this.props.onAdd(widget)
        }

    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({errorTitle: false});
    };

    render() {
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Edit Text</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Write the Edit text name and default value if there is any.
                    </DialogContentText>
                    <TextField
                        style={{marginTop: '1em', marginBottom: '1em'}}
                        error={this.state.errorTitle}
                        id={"name"}
                        label='Title'
                        value={this.state.widget.title}
                        fullWidth
                        onChange={this.handleChange('name')}
                        required/>

                    <TextField
                        style={{marginBottom: '1em'}}
                        id={"defaultValue"}
                        label='Default Value'
                        value={this.state.widget.defaultValue}
                        fullWidth
                        type={'number'}
                        onChange={this.handleChange('defaultValue')}
                    />

                    <TextField
                        style={{marginBottom: '1em'}}
                        id={"min"}
                        label='Minimum Value'
                        value={this.state.widget.min}
                        fullWidth
                        type={'number'}
                        onChange={this.handleChange('min')}
                    />

                    <TextField
                        style={{marginBottom: '1em'}}
                        id={"max"}
                        label='Maximum Value'
                        value={this.state.widget.max}
                        fullWidth
                        type={'number'}
                        onChange={this.handleChange('max')}
                    />
                    <FormControlLabel
                        style={{marginBottom: '1em'}}
                        control={
                            <Switch
                                onChange={this.handleChange('optional')}
                                name={"optional"}
                                checked={this.state.widget.optional}/>
                        }
                        label={"Optional"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button color={'primary'} onClick={this.verifyAndAdd}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SeekbarView;